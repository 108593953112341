import http from '@/utils/http';


export const getTournaments = (parameter) => {
  return http.request({
    url: '/v1/relation/games/tournament/list',
    method: 'get',
    params: parameter
  })
}
